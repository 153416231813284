import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private http: HttpClient) {}

  getById(id: string): Observable<any> {
    return this.http.get(`${environment.serviceUrl}/brands/${id}`);
  }

  getAll(requestParams: any): Observable<any> {
    return this.http.get(`${environment.serviceUrl}/brands`, {
      params: HttpUtils.getHttpParams(requestParams),
    });
  }

  all(): Observable<any> {
    return this.http.get(`${environment.serviceUrl}/brands/all`);
  }

  create(createRequest: any): Observable<any> {
    return this.http.post(`${environment.serviceUrl}/brands`, createRequest);
  }

  update(id: string, updateRequest: any): Observable<any> {
    return this.http.put(
      `${environment.serviceUrl}/brands/${id}`,
      updateRequest
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.serviceUrl}/brands/${id}`);
  }

  getAllIndustries(): Observable<any> {
    return this.http.get(`${environment.serviceUrl}/brands/industries`);
  }

  getScheduledPostsNotifications(id: string, filter: any): Observable<any> {
    return this.http.get(
      `${environment.serviceUrl}/brands/${id}/validate-social-media-scheduled-posts`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getTokenExpiryNotificaiton(): Observable<any> {
    return this.http.get(
      `${environment.serviceUrl}/social-media-connections/status-check`
    );
  }
}
